<script>

export default {
    name: "MentionsLegalesPage",
    components: {

    },
    props: ['templateMode'],
    created(){
         this.$emit('headerState', 1);
    },
    methods: {
        

    }
};
</script>

<style lang="scss">
    @import "mentionslegales-page.scss";
</style>

<template src="./mentionslegales-page.html"></template>